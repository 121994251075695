import { Component, Vue } from 'vue-property-decorator'
import { SharedService } from '@/App/Services/Shared.service'

@Component
export default class ItemsListDialog extends Vue {
  public isOpen = false
  public isLoading = false
  public search = ''
  public title = ''
  public originalItems: string[] = []
  public items: string[] = []

  public async open(title: string, items: string[]): Promise<void> {
    this.isOpen = true

    this.title = title
    this.originalItems = items
    this.items = items
  }

  public close(): void {
    this.isOpen = false
  }

  public onSearch(text: string): void {
    if (!text) {
      this.items = this.originalItems
    } else {
      this.items = this.originalItems.filter(i => i.toLowerCase().includes(text.toLowerCase()))
    }
  }

  public async exportToExcel(): Promise<void> {
    try {
      this.isLoading = true

      await SharedService.toExcel(this.title, this.originalItems)
    } catch {} finally {
      this.isLoading = false
    }
  }
}
