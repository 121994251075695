import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { IOrganization } from '@/App/Services/interfaces/organization.interface'
import { VuetifySelect } from '@/interfaces/vuetify'

const organizationsStore = namespace('Organization')

@Component
export default class OrganizationSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @organizationsStore.State
  public readonly organizations: IOrganization[]

  @organizationsStore.Action
  private readonly fetchOrganizations: () => Promise<void>

  public isLoading = false
  public organizationId = ''

  @Watch('value')
  private valueChanged(val: string): void {
    this.organizationId = val
  }

  get organizationItems(): VuetifySelect[] {
    const items: VuetifySelect[] = []

    this.organizations.forEach(o => items.push({ text: o.name, value: o._id }))

    return items
  }

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true

      await this.fetchOrganizations()
    } catch {} finally {
      this.isLoading = false
    }
  }
}
