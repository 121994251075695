import { render, staticRenderFns } from "./Organization-Summary-Card.vue?vue&type=template&id=3f12ad7e&scoped=true&"
import script from "./OrganizationSummaryCard.ts?vue&type=script&lang=ts&"
export * from "./OrganizationSummaryCard.ts?vue&type=script&lang=ts&"
import style0 from "./OrganizationSummaryCard.scss?vue&type=style&index=0&id=3f12ad7e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f12ad7e",
  null
  
)

export default component.exports