import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SharedService } from '@/App/Services/Shared.service'
import OrganizationSummaryCard from './OrganizationSummaryCard/Organization-Summary-Card.vue'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'
import ItemsListDialog from './ItemsListDialog/Items-List-Dialog.vue'
import OrganizationSelect from '@/App/Components/OrganizationSelect/Organization-Select.vue'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component({
  components: { OrganizationSummaryCard, CircularLoader, ItemsListDialog, OrganizationSelect }
})
export default class OrganizationSummaryPage extends Vue {
  $refs: {
    itemsListDialog: HTMLFormElement
  }

  @sharedStore.State
  public readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public organizationSummaryItems: OrganizationSummaryItem[] = []
  public widgets: string[] = []
  public devices: string[] = []

  private mounted(): void {
    if (!this.isUserRoot) {
      this.setData(this.userInfo.organizationId)
    }
  }

  private async setData(organizationId: string): Promise<void> {
    try {
      this.isLoading = true
      this.organizationSummaryItems = []

      const accountSummary = await SharedService.fetchAccountSummary(organizationId)

      this.widgets = accountSummary.widgetsName
      this.devices = accountSummary.devicesName

      Object.keys(accountSummary).forEach(key => {
        switch (key) {
          case 'managers':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.managersTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxManagers,
              icon: 'fa fa-users'
            })
            break
          case 'operators':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.operatorsTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxOperators,
              icon: 'fa fa-users'
            })
            break
          case 'monitoringDashboards':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.monitoringTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxMonitoringDashboards,
              icon: 'fa fa-tachometer-alt'
            })
            break
          case 'insightsDashboards':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.insightsTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxInsightsDashboards,
              icon: 'fa fa-tachometer-alt'
            })
            break
          case 'widgets':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.widgetsTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxWidgets,
              icon: 'fab fa-chromecast'
            })
            break
          case 'notifications':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.notificationsTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxNotifications,
              icon: 'fa fa-bell'
            })
            break
          case 'scheduledReports':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.scheduledReportsTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxScheduledReports,
              icon: 'fa fa-calendar-alt'
            })
            break
          case 'devices':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.devicesTitleText').toString(),
              value: accountSummary[key],
              maxValue: accountSummary.maxDevices,
              icon: 'fa fa-broadcast-tower'
            })
            break
          case 'smsLimit':
            this.organizationSummaryItems.push({
              title: this.$t('organizationSummaryPage.smsLimitTitleText').toString(),
              value: accountSummary.smsSent,
              maxValue: accountSummary.smsLimit,
              icon: 'fa fa-envelope'
            })
            break
        }
      })
    } catch {} finally {
      this.isLoading = false
    }
  }

  public onOrganizationChanged(organizationId: string): void {
    this.setData(organizationId)
  }

  public onCardClick(name: string): void {
    if (name === 'Widgets') {
      this.$refs.itemsListDialog.open(this.$t('organizationSummaryPage.allWidgetsOrganizationText').toString(), this.widgets)
    }

    if (name === 'Devices') {
      this.$refs.itemsListDialog.open(this.$t('organizationSummaryPage.allDevicesOrganizationText').toString(), this.devices)
    }
  }

  get isUserRoot(): boolean {
    return this.userInfo.role === 'root'
  }
}

interface OrganizationSummaryItem {
  title: string
  value: number
  maxValue: number
  icon: string
}
