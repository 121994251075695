import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class OrganizationSummaryCard extends Vue {
  @Prop({ required: true })
  public readonly title: string

  @Prop({ required: true })
  public readonly value: number

  @Prop({ required: true })
  public readonly maxValue: number

  @Prop({ required: true })
  public readonly icon: string

  get valueColor(): string {
    if (this.value >= this.maxValue) {
      return 'value-max'
    } else if (this.value + 4 >= this.maxValue) {
      return 'value-mid'
    } else {
      return 'value-low'
    }
  }
}
